<!--
 * @Author: xiaocai 2106553620@qq.com
 * @Date: 2022-08-15 09:40:18
 * @LastEditors: xiaocai 2106553620@qq.com
 * @LastEditTime: 2022-08-27 13:44:29
 * @FilePath: \mid-autumn\src\App.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div id="add">
    <!-- 一级路由出口 -->
    <audio preload="auto" loop id="audio" :src="require('./assets/shenmideyuzhou.mp3')"></audio>
    <div @click="changeOn" :class="isOff ? 'isOff' : 'isOn'"></div>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: 'MidAutumnApp',

  data() {
    return {
      isOff: true,
    };
  },

  mounted() {
    // 自动播放音乐效果，解决微信自动播放问题
    document.addEventListener('touchstart', this.audioAutoPlay, false);
    document.addEventListener('WeixinJSBridgeReady', this.audioAutoPlay, false);
    let oAudio = document.querySelector('#audio');
    oAudio.onended = function () {
      //播放完毕，重新循环播放
      oAudio.load();
      oAudio.play();
    };
  },

  methods: {
    changeOn() {
      let oAudio = document.querySelector('#audio');
      if (this.isOff) {
        oAudio.play(); //让音频文件开始播放
      } else {
        oAudio.pause(); //让音频文件暂停播放
      }
      this.isOff = !this.isOff;
    },
    audioAutoPlay() {
      let audio = document.getElementById('audio');
      this.isOff = false;
      audio.play();
      document.removeEventListener('touchstart', this.audioAutoPlay);
    },
  },
};
</script>
<style lang="scss">
html,
body,
#add {
  width: 100%;
  height: 100%;
  font-family: FF;
}
.isOn {
  width: 32px;
  height: 32px;
  position: fixed;
  z-index: 2000;
  top: 17%;
  left: 8%;
  -webkit-animation: rotating 1.2s linear infinite;
  animation: rotating 1.2s linear infinite;
  background: url('./assets/播放.png') 0 0 no-repeat;
  background-size: 100%;
}
@keyframes rotating {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes rotating {
  from {
    -webkit-transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}
.isOff {
  width: 32px;
  height: 32px;
  position: fixed;
  z-index: 2000;
  top: 17%;
  left: 8%;
  background: url('./assets/暂停.png') 0 0 no-repeat;
  background-size: 100%;
}
</style>
